import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './DataTable.css'
export default function DataTable({data, columns, link}) {
  
    return (
      <div style={{ height: "100%", width: '100%' ,padding:"2rem 2rem"}}>
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </div>
    );
  }