import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    delegates:[],
    messageUS:'',
    isLoading:false,
    isErrorUS:false,
    isSuccessUs:false,
}


// Get All the Delegates
export const GetAllDelegates = createAsyncThunk('delegates/GetAllDelegates', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getAllDelegate`;

        const response = await axios.get(API_URL) 
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const delegateslice = createSlice({
    name:'delegateslice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{            
            state.delegates = state.delegates.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(GetAllDelegates.pending, (state, action) => {
            state.isLoading = true;
          })
          .addCase(GetAllDelegates.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccessUS = true;
            state.isErrorUS = false;
            state.messageUS = "";
      
            if (action.payload && action.payload.length > 0) {
              // Update the state with the payload data
              state.delegates = action.payload;
            } else {
              // If the payload is empty or not an array, set delegates to an empty array
              state.delegates = [];
            }
          })
          .addCase(GetAllDelegates.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccessUS = false;
            state.isErrorUS = true;
            state.messageUS = action.payload;
            state.delegates = [];
          });
      }
     }
)


export const {reset,filterState} = delegateslice.actions
export default delegateslice.reducer