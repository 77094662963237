import Header from "../../components/Temp_header/Temp_header"
import '../FormDelegate/FormDelegate.css'
import React ,{useState} from "react"
import {registerEntry, getEntry} from '../../features/Register/Register'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import { countries } from 'countries-list'; 



const FormEntry = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [agreed, setAgreed] = useState(false);
  const { isSuccess,fullName,contact,company,email } = useSelector((store) => store.register)



const [data, setdata] = useState({
  fullName:'',
  email:'',
  contact:'',
  gender:'',
  country:'',
  city:'',
  profileImg:'',
  company:'',
  jobTitle:'',
  agreed:'',
})


// const handleRecaptchaChange = (value) => {
//   setRecaptchaValue(value);
// };


const ValueUpdater = (e)=>{
    setAgreed(e.target.checked);
    setdata({...data , [e.target.name]:e.target.value})

    if (e.target.name === 'email' && /\S+@\S+\.\S+/.test(e.target.value)) {
      dispatch(getEntry({ email: e.target.value }));
    }
    }

const ValueUpdater1 = (value) => {
    setdata({ ...data, contact: value });
};


    const submit =async (e)=>{
      e.preventDefault()
      // if (!recaptchaValue) {
      //   alert("Please complete the reCAPTCHA");
      //   return;
      // }
      
      const formData = new FormData()
      formData.append('email', data.email)
      formData.append('fullName', data.fullName)
      formData.append('contact', data.contact)
      formData.append('gender', data.gender)
      formData.append('company', data.company)
      formData.append('jobTitle', data.jobTitle)
      formData.append('country', data.country)
      formData.append('city', data.city)
      formData.append('agreed', data.agreed)
      formData.append('profileImg', data.profileImg)


      const {fullName,email,contact} = data
      if(fullName==='' || email===''|| contact==='' || !agreed){
        toast.info('Please fill all fields and agree to receive communication.');
      }
      else{
          dispatch(registerEntry(formData))
      }
    }

  useEffect(()=>{
    if(isSuccess){
      navigate('/entryinvoice')
    }
  },[isSuccess]) 
  
  
  useEffect(() => {
    if (fullName && contact && company && email) {
      setdata({
        ...data,
        fullName,
        email,
        contact,
        company,
      });
    }
  }, [fullName, contact, company, email]);


  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));

  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountryChange = (selectedCountry) => {
    setSelectedCountry(selectedCountry)
    const selectedCountryCode = selectedCountry ? selectedCountry.value : '';
    const selectedCountryName = countries[selectedCountryCode]?.name || '';
  
    // Update the 'data' state with the selected country name
    setdata({ ...data, country: selectedCountryName });
  
  };



  return (
    <div className="RigisterFormParent">
      <Header/>
      <div className='speaker_text header-register'>
       <h2>Curate Tomorrow Checkin</h2>
      </div>
      
      <div className="formParent">
      <form className="registrationForm" encType="multipart/form-data">
        <div className="formInputs">
          <div>
          <label htmlFor="email">Email Address *</label>
          <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="firstName">Full Name *</label>
          <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="gender">Gender</label>
          <select style={{color: '#2a2828',fontSize:'14px', fontWeight:'normal'}} id="gender" name="gender" value={data.gender} onChange={ValueUpdater}>
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="preferNotToSpecify">Prefer Not to Specify</option>
          </select>
          </div>


          <div style={{display:'flex', flexDirection:'column',justifyContent:'initial'}}>
          <label htmlFor="contact">Mobile *</label>
          <PhoneInput inputStyle={{width:'100%', height:'2.5rem', borderRadius:'3px'}} country={'no'} countryCodeEditable={true} placeholder="Enter phone number" value={data.contact} onChange={ValueUpdater1}/>
          </div>

          <div style={{display:'flex', flexDirection:'column',justifyContent:'initial'}}>
          <label htmlFor="country">Home Country</label>
          <Select
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countryOptions}
        placeholder="Select a country"
        />

          {/* <input type="text" id="country" name='country' value={data.country} onChange={ValueUpdater}/> */}
          </div>

          <div>
          <label htmlFor="city">Current City</label>
          <input type="text" id="city" name='city' value={data.city} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="image">Attach Your Latest Photograph</label>
          <input type="file" id="image" accept="image/*" name="profileImg" onChange={(e)=>setdata({...data,["profileImg"]:e.target.files[0]})}/>
          </div>

          <div>
          <label htmlFor="company">Company</label>
          <input type="text" id="company" name='company' value={data.company} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="jobTitle">Job Title</label>
          <input type="text" id="jobTitle" name='jobTitle' value={data.jobTitle} onChange={ValueUpdater}/>
          </div>

          <div style={{width:'100%'}}>
          <label style={{textTransform:'revert'}}>
          <input type="checkbox" id="agreed" name="agreed" checked={agreed} onChange={ValueUpdater}/> Agree to receive communication and allow content sharing of the day.
          </label>
          </div>


        </div>

        {/* <div style={{ textAlign: "left" }}>
          <ReCAPTCHA
          sitekey="6LevkE8oAAAAAE0by3wZ6WSQYYTBLdA0gdIq_H6k"
          onChange={handleRecaptchaChange}
          />
        </div> */}

        <div style={{textAlign:'left'}}>
          <button type="submit" className="btn" onClick={submit}>Submit</button>
        </div>
      </form>
      </div>
    </div>
  )
}

export default FormEntry;