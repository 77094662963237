import "./Temp_hero.css"
import Header from "../Temp_header/Temp_header";
const Hero = () => {
  
  return (
    <div className="hero" id="hero">
      <Header/>
      <div className="pinkHero"></div>
      <div className="home_heading">
        <h2 className="separateLine">Curate Tomorrow</h2>
        {/* <ul className="ul">
          <li>26 September, 2024</li>
          <li>Oslo, Norway</li>
        </ul>
        <ul className="ul">
          <li>Oslo Innovation Week 23-27 September,2024</li>
        </ul> */}
        <ul className="ul">
          <li>We would be back with the latest dates soon</li>
        </ul>
      </div>
    </div>
  )
}

export default Hero