import React from 'react'

const PrevCTDot2 = () => {
  return (
    <div className='prevDot2'>
        <svg width="318" height="182" viewBox="0 0 318 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.9146 63.3582L120.792 137.012L293.566 63.3979L316.3 73.3721" stroke="black" strokeWidth="1.05328" strokeDasharray="5.27 5.27"/>
            <path d="M49.529 30.6588C31.8303 25.9134 13.6358 36.4143 8.89044 54.113C4.14512 71.8118 14.646 90.0063 32.3447 94.7516C50.0434 99.4969 68.238 88.9961 72.9833 71.2973C77.7286 53.5986 67.2278 35.4041 49.529 30.6588Z" fill="#E6007E"/>
            <path d="M294.645 54.6091C289.589 53.2533 284.39 56.2535 283.034 61.3103C281.678 66.3671 284.679 71.5655 289.735 72.9213C294.792 74.2771 299.991 71.2769 301.347 66.2201C302.702 61.1633 299.702 55.9649 294.645 54.6091Z" fill="#E6007E"/>
            <path d="M122.02 126.247C116.963 124.891 111.765 127.891 110.409 132.948C109.053 138.005 112.053 143.203 117.11 144.559C122.167 145.915 127.365 142.915 128.721 137.858C130.077 132.801 127.077 127.603 122.02 126.247Z" fill="#E6007E"/>
        </svg>
  </div>
  )
}

export default PrevCTDot2
