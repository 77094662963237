import React from 'react'

const PrevCTDot1 = () => {
  return (
    <div className='prevDot1'>
        <svg width="321" height="111" viewBox="0 0 321 111" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M282.096 72.0708L0.131836 1.20129" stroke="black" strokeWidth="1.05407" strokeDasharray="5.27 5.27"/>
          <path d="M282.097 110.544C303.345 110.544 320.57 93.3192 320.57 72.0707C320.57 50.8223 303.345 33.597 282.097 33.597C260.848 33.597 243.623 50.8223 243.623 72.0707C243.623 93.3192 260.848 110.544 282.097 110.544Z" fill="#E6007E"/>
        </svg>
      </div>
  )
}

export default PrevCTDot1
