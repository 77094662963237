import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './SideBar.css'
const SideBar = () => {
  const location = useLocation();
  // let active = '';
  let [active, setActive] = useState( (location.pathname.includes("delegate"))? {
    active : "delegate"
  }: (location.pathname.includes("sponsor")) ? {
    active : "sponsor"
  } : {
    active : "entry"
  }
)


  if (location.pathname.includes("entry")) {
    active = "entry";
  } else if (location.pathname.includes("sponsor")) {
    active = "sponsor";
  } else {
    active = "delegate";
  }

  return (
    <div className='SideBarDash'>
      <div className='SideBar_divDash'>
      <div className='SideBar_imgDash'>
          <img src={require('../../Assets/logo.png')} alt="" />
        </div>

        <div className='SideBar_LinksDash'>
            <Link to="/dashboard/delegate" className={active==="delegate"?"activeDash":"deactiveDash"} onClick={()=>setActive("delegate")}>DELEGATE</Link>
            <Link to="/dashboard/sponsor" className={active==="sponsor"?"activeDash":"deactiveDash"} onClick={()=>setActive("sponsor")}>SPONSOR</Link>
            <Link to="/dashboard/entry" className={active==="entry"?"activeDash":"deactiveDash"} onClick={()=>setActive("entry")}>ENTRY</Link>
        </div>
      </div>    
    </div>
  )
}

export default SideBar