import './AboutUs.css'

const AboutUs = ({headingTag, contentTag}) => {
  return (
    <div className='flex_row_c aboutDiv'>
        <h4>{headingTag}</h4>
        <p>{contentTag}</p>
    </div>
  )
}

export default AboutUs
