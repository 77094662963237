import { configureStore } from '@reduxjs/toolkit';
import InvoiceSlice from '../features/InvoiceSlice/InvoiceSlice';
import RegisterSlice from '../features/Register/Register'
import authSliceReducer from '../features/authSlice/authSlice';
import delegateReducer from '../features/DelegateSlice/DelegateSlice'
import sponsorReducer from '../features/SponsorSlice/SponsorSlice'
import entriesReducer from '../features/EntrySlice/EntrySlice'


export const store = configureStore({
    reducer:{
        invoice:InvoiceSlice,
        register:RegisterSlice,
        delegates:delegateReducer,
        sponsors:sponsorReducer,
        entries:entriesReducer,
        auth: authSliceReducer,
    }
});
