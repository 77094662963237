import React from 'react'
import { Link } from 'react-router-dom'
import './SponsorshipCards.css'

const SponsorshipCards = ({name,price,Perk1,Perk2,Perk3,Perk4}) => {
  return (
    <div className="spnsorship-card">
        <div className='card_headings'>
            <div className='card_heading1'>
                <h5>{name}</h5>
                <div className='card_heading1Underline'></div>
            </div>
            <div className='card_heading2'>
                <h3>{price}</h3>
            </div>
        </div>
        <ul className='ul listPerks'>
        {Perk1 && <li>{Perk1}</li>}
        {Perk2 && <li>{Perk2}</li>}
        {Perk3 && <li>{Perk3}</li>}
        {Perk4 && <li>{Perk4}</li>}
        </ul>
        <Link to="/register-sponsor" className=" btn sponsorship-btn">Partner Now</Link>
    </div>

  )
}

export default SponsorshipCards
