import React, { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import DataTable from '../../DashboardComponents/DataTable/DataTable';
import {GetAllSponsors} from '../../features/SponsorSlice/SponsorSlice'

const SponsorsPage = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const dispatch = useDispatch()

  const SponsorsColoumns = [
    { field: 'fullName', headerName: 'Full Name', width: 200,},
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'contact', headerName: 'Contact', width: 200 },
    { field: 'paymentStatus', headerName: 'Payment Status', width: 200},
    { field: 'amount', headerName: 'Amount', width: 200},
    { field: 'regitrationType', headerName: 'Registration Type', width: 200},
    { field: 'createdAt', headerName: 'CreatedAt', width: 200},
  ];
  const {sponsors} = useSelector((store)=>store.sponsors)

  useEffect(()=>{
    dispatch(GetAllSponsors())
    
  },[dispatch])

  const filteredSponsors = sponsors.filter((sponsor) => {
    const year = new Date(sponsor.createdAt).getFullYear();
    return year === parseInt(selectedYear);
  });


  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };



  return (
    <div>
      <h3 style={{padding:'2rem'}}>Sponsor</h3>

      <div style={{ padding: '2rem' }}>
        <label htmlFor="year-select">Select Year: </label>
        <select id="year-select" value={selectedYear} onChange={handleYearChange}>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
      </div>

      <DataTable columns={SponsorsColoumns} data={filteredSponsors}/>
    </div>
  )
}

export default SponsorsPage