import './VenueComp.css'

import {AiOutlineArrowRight} from 'react-icons/ai'
import { Link } from 'react-router-dom'

const VenueComp = () => {
  return (
    <div className='flex_row_sb venueDiv'>
          <div className='ticketDiv_left venue_left'>
            <h2>The Venue</h2>
            <p>Nio House, Karl Johans gt. 33, 0162  Oslo, Norway</p>
            <Link to="/contact" className='secondary_btn venueBtn underLine'>View On Map <AiOutlineArrowRight/></Link>
          </div>
          <div className='venue_right'>
            <img src={require('../../Assets/sectionImg/conference.png')}></img>
          </div>
    </div>
  )
}
export default VenueComp