import './Home.css'
import '../../pages/Speakers/Speakers.css'
import Hero from "../../components/Temp_hero/Temp_hero";
import BannerLine from '../../components/Temp_bannerLine/Temp_bannerLine';
import AboutUs from '../../components/AboutUs/AboutUs';
import PreviousCT from '../../components/PreviousCT/PreviousCT';
import Agenda from '../../components/Agenda/Agenda';
import VenueComp from '../../components/VenueComp/VenueComp'
import TicketComp from '../../components/TicketComp/TicketComp'
import SpeakerList from '../../components/SpeakerList/SpeakerList'
import PrevCTDot2 from '../../components/PrevCTDot2/PrevCTDot2';
import PrevCTDot1 from '../../components/PrevCTDot1/PrevCTDot1';
import AgendaDot1 from '../../components/AgendaDot1/AgendaDot1';
import Sponsorship from '../../components/Sponsorship/Sponsorship';
import SubscribePopup from '../../components/SubscribePopup/SubscribePopup';
import { useState } from 'react';
import { useEffect } from 'react';
import Partners from '../../components/Partners/Partners';



const Home = () => {
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);

  useEffect(() => {
  const popupTimer = setTimeout(() => {
    setIsSubscribePopupOpen(true);
  }, 6000);

  return () => {
    clearTimeout(popupTimer);
  };
}, []);

const handleCloseSubscribePopup = () => {
  setIsSubscribePopupOpen(false);
};


  return (
    <>
    <div>

      <Hero/>
      <BannerLine/>
      <AboutUs headingTag='About us' contentTag={`We are absolutely thrilled to join hands with Oslo Innovation Week 2024 (OIW24) 
        in hosting our third think-tank cluster meet up, Curate Tomorrow. This exciting 
        event will bring together an incredible mix of delegates from diverse backgrounds, 
        including C40 representatives, accelerators, startups, scale-ups, government leaders, 
        diplomats, artists, and futurists and we can't wait to welcome everyone to the vibrant 
        city of Oslo, where the event will be hosted in the heart of Oslo at NIO House.`}/>
      <div className='speaker_text'>
        <h2>2024 Speakers</h2> 
        <SpeakerList/>
      </div>
      {isSubscribePopupOpen && <SubscribePopup onClose={handleCloseSubscribePopup} />}
      <PrevCTDot1/>
      <PreviousCT/>
      <PrevCTDot2/>
      <AgendaDot1/>
      <Agenda headingTag='What do Curate Tomorrow  wants to do?' contentTag={`        Get ready to witness the power of collaboration as we 
        bring together change-makers in the battle against climate 
        change at our upcoming event, Curate Tomorrow. This gathering 
        marks the continuation of a transformative 10-year mission out of 
        which we are left with 8 years to reverse the environmental damage 
        we've witnessed over the years. Our goal is to adopt a startup scale-up mindset, 
        amplifying the impact of climate-focused technologies, policies, brilliant ideas, 
        and proven best practices shared by our esteemed delegates and their organisations. 
        Join us as we kick off the event with round table introductions, setting the stage for an 
        immersive design thinking-led session that will shape the think tank's 10-year open mandate. 
        Experience a dynamic exchange of ideas, technologies, and insights on their current impact as 
        participants and organisations come together. It's through this collective knowledge sharing 
        that we can drive meaningful change on a global scale.
      `}/>
      <Sponsorship/>
      <VenueComp/>
      <Partners/>
      <TicketComp/>

    </div>
    </>
  )
}
export default Home