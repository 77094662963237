import Header from "../../components/Temp_header/Temp_header"
import '../FormDelegate/FormDelegate.css'
import React ,{useState} from "react"
import {registerSponsor} from '../../features/Register/Register'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const FormSponsor = () => {
  const dispatch = useDispatch()


const [data, setdata] = useState({
  email:'',fullName:'',
  contact:'',
  organizationName:'',
  regitrationType:'',
  amount:'',
  person:'sponsor'

})



const ValueUpdater = (e)=>{
  if(e.target.name==="regitrationType"){
    if(e.target.value==="Primary"){
      setdata({...data , [e.target.name]:e.target.value,['amount']:3000})
    }
    if(e.target.value==="SilverPartner"){
      setdata({...data , [e.target.name]:e.target.value,['amount']:8000})
    }
    if(e.target.value==="GoldPartner"){
      setdata({...data , [e.target.name]:e.target.value,['amount']:15000})
    }
    if(e.target.value==="PlatinumPartner"){
      setdata({...data , [e.target.name]:e.target.value,['amount']:30000})
    }
    if(e.target.value==="PoweredBy"){
      setdata({...data , [e.target.name]:e.target.value,['amount']:60000})
    }
  
  }else{
    setdata({...data , [e.target.name]:e.target.value})
  
  }
  }


    const submit =async (e)=>{
      e.preventDefault()
      const {email,fullName,contact,organizationName,regitrationType} = data
      if(email==='' || fullName==='' || organizationName===''|| regitrationType==='' || contact===''){
        toast.info('Please fill all fields')
      }
      else{
          dispatch(registerSponsor(data))
      }
    }

  return (
    <div className="RigisterFormParent">
      <Header/>
      <div className='speaker_text header-register'>
       <h2>Register as a Sponsor</h2>
      </div>
      
      <div className="formParent">
      <form className="registrationForm" encType="multipart/form-data">
        <div className="formInputs">
          <div>
          <label htmlFor="email">Email Address *</label>
          <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="contact">Contact *</label>
          <input type="text" id="contact" name='contact' value={data.contact} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="FullName">Full Name *</label>
          <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="OrganizationsName">Organizations Name *</label>
          <input type="text" id="organizationName" name='organizationName' value={data.organizationName} onChange={ValueUpdater}/>
          </div>
        </div>

        <div className="formOptions">
        <div className="radioDiv">
          <p>Select Sponsor Type *</p>

          <div>
          <div>
            <input type="radio" id="Primary" name="regitrationType" value="Primary" onChange={ValueUpdater}/>
            <label htmlFor="Primary">Primary ($3000)</label>
          </div>

          <div>
            <input type="radio" id="SilverPartner" name="regitrationType" value="SilverPartner" onChange={ValueUpdater}/>
            <label htmlFor="SilverPartner">Silver Partner ($8000)</label>
          </div>

          <div>
            <input type="radio" id="GoldPartner" name="regitrationType" value="GoldPartner" onChange={ValueUpdater}/>
            <label htmlFor="GoldPartner">Gold Partner ($15000)</label>
          </div>

          <div>
            <input type="radio" id="PlatinumPartner" name="regitrationType" value="PlatinumPartner" onChange={ValueUpdater}/>
            <label htmlFor="PlatinumPartner">Platinum Partner ($30000)</label>
          </div>

          <div>
            <input type="radio" id="PoweredBy" name="regitrationType" value="PoweredBy" onChange={ValueUpdater}/>
            <label htmlFor="PoweredBy">Powered By ($60000)</label>
          </div>
          <input type="text" placeholder="Amount" name="amount" onChange={ValueUpdater} value={data.amount}/>


          </div>
        </div>
        </div>
        <div style={{textAlign:'left'}}>
          <button type="submit" className="btn" onClick={submit}>Submit</button>
        </div>
      </form>
      </div>
    </div>
  )
}

export default FormSponsor;