import React, { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import {GetAllDelegates} from '../../features/DelegateSlice/DelegateSlice'
import DataTable from '../../DashboardComponents/DataTable/DataTable';

const DelegatePage = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const dispatch = useDispatch()

  const DelegateColumns = [
    { field: 'fullName', headerName: 'Full Name', width: 150,},
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'contact', headerName: 'Contact', width: 150 },
    { field: 'paymentStatus', headerName: 'Payment Status', width: 150},
    { field: 'amount', headerName: 'Amount', width: 150},
    { field: 'regitrationType', headerName: 'Registration Type', width: 150},
    { field: 'createdAt', headerName: 'CreatedAt', width: 200},  
  ];

  const { delegates } = useSelector((store) => store.delegates);
  useEffect(()=>{
    dispatch(GetAllDelegates())
    
  },[dispatch])


  const filteredDelegates = delegates.filter((delegate) => {
    const year = new Date(delegate.createdAt).getFullYear();
    return year === parseInt(selectedYear);
  });


  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };


  
  return (
    <div>
      <h3 style={{padding:'2rem'}}>Delegate</h3>
      
      <div style={{ padding: '2rem' }}>
        <label htmlFor="year-select">Select Year: </label>
        <select id="year-select" value={selectedYear} onChange={handleYearChange}>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
      </div>

      <DataTable columns={DelegateColumns} data={filteredDelegates}/>
    </div>
  )
}

export default DelegatePage