// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    position: absolute;
    color: var(--t-lgrey);
    z-index: 2;
    width: 100%;
}
.footer_top{
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding: 1.5rem 5rem;
    background-color: var(--t-black);
    gap: 0.5rem;
}
.footer_top_left{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    gap: 1rem;
    /* margin-top: 1rem; */
}
.linkFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:smaller
}
@media only screen and (max-width: 800px) {
    .footer_top{
        flex-direction: column;
        padding: 1rem 3rem;
        gap: 1rem;
        
    }
    .footer_top_right{
        /* flex-direction: column; */
        gap: 0.5rem;
        /* justify-content: center; */
    }
    .footer_top_left{
        gap: 1rem;
    }
}
@media only screen and (max-width: 550px) {
    .footer_top{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,UAAU;IACV,WAAW;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,oBAAoB;IACpB,gCAAgC;IAChC,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,SAAS;IACT,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB;AACJ;AACA;IACI;QACI,sBAAsB;QACtB,kBAAkB;QAClB,SAAS;;IAEb;IACA;QACI,4BAA4B;QAC5B,WAAW;QACX,6BAA6B;IACjC;IACA;QACI,SAAS;IACb;AACJ;AACA;IACI;QACI,kBAAkB;QAClB,mBAAmB;IACvB;AACJ","sourcesContent":[".footer{\n    position: absolute;\n    color: var(--t-lgrey);\n    z-index: 2;\n    width: 100%;\n}\n.footer_top{\n    display: flex;\n    flex-direction: column;\n    z-index: 2;\n    padding: 1.5rem 5rem;\n    background-color: var(--t-black);\n    gap: 0.5rem;\n}\n.footer_top_left{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 2;\n    gap: 1rem;\n    /* margin-top: 1rem; */\n}\n.linkFooter{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size:smaller\n}\n@media only screen and (max-width: 800px) {\n    .footer_top{\n        flex-direction: column;\n        padding: 1rem 3rem;\n        gap: 1rem;\n        \n    }\n    .footer_top_right{\n        /* flex-direction: column; */\n        gap: 0.5rem;\n        /* justify-content: center; */\n    }\n    .footer_top_left{\n        gap: 1rem;\n    }\n}\n@media only screen and (max-width: 550px) {\n    .footer_top{\n        padding-left: 2rem;\n        padding-right: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
