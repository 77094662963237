import './Main.css'
import { Outlet } from "react-router-dom";
import Header from '../../DashboardComponents/Header/Header'
import SideBar from '../../DashboardComponents/SideBar/SideBar'


const Main = () => {
  return (
    <div className='mainPageDash'>
<div className='HomeDash'>
      <div className='Home_leftDash'>
      <SideBar/>
      </div>

      <div className='Home_rightDash'>
        <div className='Home_right_topDash'>
          <Header/>
        </div>
        <div className='Home_right_bottomDash'>
          <Outlet/>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Main
