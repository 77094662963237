import React, { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import DataTable from '../../DashboardComponents/DataTable/DataTable';
import { GetAllEntry } from '../../features/EntrySlice/EntrySlice';


const EntryPage = () => {
  const dispatch = useDispatch()
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


  const EntriesColumns = [
    { field: 'fullName', headerName: 'Full Name', width: 150,},
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'contact', headerName: 'Contact', width: 150 },
    { field: 'company', headerName: 'Company', width: 150},
    { field: 'jobTitle', headerName: 'Job Title', width: 150},
    { field: 'counter', headerName: 'Entry Number', width: 150},
    { field: 'createdAt', headerName: 'CreatedAt', width: 200},  
  ];

  const { entries } = useSelector((store) => store.entries);
  useEffect(()=>{
    dispatch(GetAllEntry())
    
  },[dispatch])

  const filteredEntries = entries.filter((entry) => {
    const year = new Date(entry.createdAt).getFullYear();
    return year === parseInt(selectedYear);
  });


  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  
  return (
    <div>
      <h3 style={{padding:'2rem'}}>Entries</h3>

      <div style={{ padding: '2rem' }}>
        <label htmlFor="year-select">Select Year: </label>
        <select id="year-select" value={selectedYear} onChange={handleYearChange}>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
      </div>

      <DataTable columns={EntriesColumns} data={filteredEntries}/>
    </div>
  )
}

export default EntryPage