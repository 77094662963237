import './PreviousCT.css'
import { Link } from "react-router-dom";
import {previousSpeakers} from '../../Assets/data'


const PreviousCT = () => {
  return (
<div className='flex_row_c previousCT'>

      <div className='flex_row_c text_previousCT'>
        <h4>Previously on Curate Tomorrow</h4>
        <p>
              We are thrilled with the resounding success of our last year event! 
              Heartfelt appreciation to all the passionate participants who 
              contributed to meaningful discussions and collaborative efforts. 
              Together, we are making a difference in the fight against climate 
              change and curating a sustainable tomorrow. Memories captured in 
              vibrant pictures that tell stories of collaboration, inspiration, 
              and the collective pursuit of a sustainable future.
        </p>
      </div>
      <div className="PreviousCT_button">
        <Link to='/2023' style={{border:"2px solid #E6007E"}}className='secondary_btn'>Watch the story</Link>
      </div>
      <div className='speaker2022'>
          {previousSpeakers.map((speaker,index)=>{
            return(
              <div className='speaker22Div' key={index}>
                  <div className='speakerimg22' key={index}>
                    <img src={speaker.img} alt='speaker'></img>
                  </div>
                <small>{speaker.name}</small>
              </div>
            )
          })}
      </div>
      <Link className='secondary_btn prevBtn' to="/about">And More</Link>

</div>
  )
}

export default PreviousCT
