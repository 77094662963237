import './SpeakerList.css'
import {speakers} from '../../Assets/data'


const SpeakerList = () => {
  return (
    <div className='speaker2023'>
          {speakers.map((speaker,index)=>{
            return(
              <div className='speaker23Div' key={index}>
                <a href={speaker.linkedIn} target="_blank" rel="noopener noreferrer">
                  <div className='speakerimg23' key={index}>
                    <img src={speaker.img} alt='speaker'></img>
                  </div>
                </a>
                  <div className='detailsBelow'>
                    <p>{speaker.name}</p>
                    <div>
                      <span>{speaker.designation}</span><span> | </span><span>{speaker.company}</span>
                    </div>
                  </div>
              </div>
            )
          })}
    </div>
  )
}

export default SpeakerList
