import "./HeaderSimple.css";
import "../Temp_header/Temp_header.css";

import {useState,useEffect} from "react";
import {Link} from 'react-router-dom'


const Header = () => {
  const [showH, setShowH] = useState(false);
const handleScroll = () => {
    const position = window.pageYOffset;
    if(position>100){
      setShowH(true)
    }else{
      setShowH(false)
    }

};

useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [window.pageYOffset]);

  return (
  <>{showH===true?<>
  <div className='header-simple'>
        <div className="header_left">
        <img className="" src={require('../../Assets/logo/eventLogo.png')} alt="logo"/>
      <h6 className="separateLine" style={{fontSize:"1rem",lineHeight:"1.2rem"}}>CURATE TOMORROW SUMMIT</h6>

        </div>
        <div className="header_right">
        <Link to="/">HOME</Link>
      <Link to="/about">ABOUT</Link>
      <Link to="/event">EVENT</Link>
      <Link to="/speakers">SPEAKERS</Link>
      {/* <Link to="/venue">VENUE</Link> */}
      <div className="dropdown">
        <Link className="dropbtn">HISTORY</Link>
          <div className="dropdown-content">
            <Link
              to="/2022"
            >
              CURATE TOMORROW 2022
            </Link>
            <Link
              to="/2023"
            >
              CURATE TOMORROW 2023
            </Link>
          </div>
      </div>
      <Link to="/contact">CONTACT</Link>
      <Link to="/register-sponsor">SPONSOR</Link>
      <Link to="/checkin">CHECK- IN</Link>



        </div>
              <Link className="btn" to="/register-delegate">REGISTER</Link>


    {/* <button onClick={()=>toast.info('Tickets will be available soon')} className="btn">BUY TICKETS</button> */}
    </div>
    </>:null}</>
  )
}

export default Header;