// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header-simple{
    background-color: var(--t-pink);
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 300;
    width: 100%;
    padding: 0rem 3rem;
    height: 7rem;
    transition: .5s all linear;
    animation: header .5s linear ;
    color: white;
    

}
@keyframes header {
    0%{
        transform: translateY(-100px);
    }
    100%{
        transform: translateY(0);
    }
}

@media only screen and (max-width: 1200px) {
    .header-simple{
        padding: 0rem 1rem 0rem 1rem;
        gap: 1rem;
    }
}
@media only screen and (max-width: 1100px) {
    .header-simple{
        display: none;
    }
    .header-simple>*{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderSimple/HeaderSimple.css"],"names":[],"mappings":";AACA;IACI,+BAA+B;IAC/B,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,0BAA0B;IAC1B,6BAA6B;IAC7B,YAAY;;;AAGhB;AACA;IACI;QACI,6BAA6B;IACjC;IACA;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,4BAA4B;QAC5B,SAAS;IACb;AACJ;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n.header-simple{\n    background-color: var(--t-pink);\n    position: fixed;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    z-index: 300;\n    width: 100%;\n    padding: 0rem 3rem;\n    height: 7rem;\n    transition: .5s all linear;\n    animation: header .5s linear ;\n    color: white;\n    \n\n}\n@keyframes header {\n    0%{\n        transform: translateY(-100px);\n    }\n    100%{\n        transform: translateY(0);\n    }\n}\n\n@media only screen and (max-width: 1200px) {\n    .header-simple{\n        padding: 0rem 1rem 0rem 1rem;\n        gap: 1rem;\n    }\n}\n@media only screen and (max-width: 1100px) {\n    .header-simple{\n        display: none;\n    }\n    .header-simple>*{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
