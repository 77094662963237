// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.venueDiv{
    gap: 1rem;
    z-index: 4;
    padding: 0rem 5rem;
}
.venueBtn{
    /* margin-top: 2rem; */
    text-decoration: underline;
    text-decoration-thickness: 2px;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    text-align: start !important;
    text-underline-offset: 0.5rem;
    padding: 0rem !important;

}
.venue_left{
    margin-bottom: 14rem;
}
.venue_left>a{
    width: 8rem;
}
.venue_right{
    box-sizing: border-box;
    z-index: 4;
    width: 50%;
    display: flex;
    justify-content:end;
}
.venue_right>img{
    width: 50%;
}
@media only screen and (max-width: 1200px) {
    .venueDiv{
        padding: 0rem 3rem;
    }
}
@media only screen and (max-width: 800px) {
    .venueDiv{
        flex-direction: column;
        gap: 1rem;
        padding: 0rem 3rem 3rem 3rem;
    }
    .venue_right{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        height: 10%;
    }
    .venue_right>img{
        width: 50%;
    }
}
@media only screen and (max-width: 550px) {
    .venueDiv{
        padding: 0rem 2rem !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/VenueComp/VenueComp.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,0BAA0B;IAC1B,8BAA8B;IAC9B,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,4BAA4B;IAC5B,6BAA6B;IAC7B,wBAAwB;;AAE5B;AACA;IACI,oBAAoB;AACxB;AACA;IACI,WAAW;AACf;AACA;IACI,sBAAsB;IACtB,UAAU;IACV,UAAU;IACV,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;AACA;IACI;QACI,kBAAkB;IACtB;AACJ;AACA;IACI;QACI,sBAAsB;QACtB,SAAS;QACT,4BAA4B;IAChC;IACA;QACI,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,sBAAsB;QACtB,WAAW;IACf;IACA;QACI,UAAU;IACd;AACJ;AACA;IACI;QACI,6BAA6B;IACjC;AACJ","sourcesContent":[".venueDiv{\n    gap: 1rem;\n    z-index: 4;\n    padding: 0rem 5rem;\n}\n.venueBtn{\n    /* margin-top: 2rem; */\n    text-decoration: underline;\n    text-decoration-thickness: 2px;\n    display: flex;\n    gap: 0.4rem;\n    align-items: center;\n    text-align: start !important;\n    text-underline-offset: 0.5rem;\n    padding: 0rem !important;\n\n}\n.venue_left{\n    margin-bottom: 14rem;\n}\n.venue_left>a{\n    width: 8rem;\n}\n.venue_right{\n    box-sizing: border-box;\n    z-index: 4;\n    width: 50%;\n    display: flex;\n    justify-content:end;\n}\n.venue_right>img{\n    width: 50%;\n}\n@media only screen and (max-width: 1200px) {\n    .venueDiv{\n        padding: 0rem 3rem;\n    }\n}\n@media only screen and (max-width: 800px) {\n    .venueDiv{\n        flex-direction: column;\n        gap: 1rem;\n        padding: 0rem 3rem 3rem 3rem;\n    }\n    .venue_right{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        width: 100% !important;\n        height: 10%;\n    }\n    .venue_right>img{\n        width: 50%;\n    }\n}\n@media only screen and (max-width: 550px) {\n    .venueDiv{\n        padding: 0rem 2rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
