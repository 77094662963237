import "./Partners.css";
import { partners } from "../../Assets/data";

const Partners = () => {
  return (
    <div className="partner-container">
        <h4>Current and Past Partners</h4>
        <div className="partners-card-parent">
            {partners.map((partner,index)=>{
                return(
                <div key={index} className="partner-img">
                    <img src={partner.img} alt="partner"/>
                </div>
                )
            })}
        </div>
    </div>
  )
}

export default Partners