/////Current Speakers and Partners////
export const speakers=[
    {
        img:require("./speakers/Catherine_Carlton.png"),
        name:"Catherine Carlton",
        designation:"Former Mayor",
        company:"Menlo Park",
        linkedIn:"https://www.linkedin.com/in/catcarlton/"
    },
    // {
    //     img:require("./speakers/Unni_Berge.png"),
    //     name:"Unni Berge",
    //     designation:"Head of public relation",
    //     company:"Norwegian EVA",
    //     linkedIn:"https://www.linkedin.com/in/unni-berge-4aa17985/"
    // },
    // {
    //     img:require("./speakers/Marianne_Molmen.png"),
    //     name:"Marianne Molmen",
    //     designation:"Project Manager",
    //     company:"NIO",
    //     linkedIn:"https://www.linkedin.com/in/marianne-solhaug-m%C3%B8lmen-40088b139/"
    // },
    // {
    //     img:require("./speakers/Ole_Andreas_Hagen.png"),
    //     name:"Ole Andreas Hagen",
    //     designation:"Business Director",
    //     company:"NHO",
    //     linkedIn:"https://www.linkedin.com/in/ole-andreas-hagen-195928a/"
    // }, 
    {
        img:require("./speakers/Dr_Musstanser_Tinauli.png"),
        name:"Dr Musstanser Tinauli",
        designation:"CEO and Founder",
        company:"Fori",
        linkedIn:"https://no.linkedin.com/in/tinauli"
    },
    // {
    //     img:require("./speakers/Emilie_Martinsen_Christensen.png"),
    //     name:"Emilie Martinsen Christensen",  
    //     designation:"Funding Manager",
    //     company:"Nordic Edge",
    //     linkedIn:"https://www.linkedin.com/in/emilie-martinsen-christensen/"
    // }, 
    {
        img:require("./speakers/An_Ho.png"),
        name:"An Ho", 
        designation:"Head of Operations",
        company:"NIO",
        linkedIn:"https://www.linkedin.com/in/anvinhho/"
    }, 
    // {
    //     img:require("./speakers/Dr_Frank_Riemenschneider.png"),
    //     name:"Dr Frank Riemenschneider",
    //     designation:"Prof",
    //     company:"Münster University",
    //     linkedIn:"https://www.linkedin.com/in/prof-dr-frank-riemenschneider-greif-6a26a019/"
    // },
    // {
    //     img:require("./speakers/Henrik_Botten_Taubøll.png"),
    //     name:"Henrik Botten Taubøll",
    //     designation:"Haavind",
    //     company:"Proptech Norway",
    //     linkedIn:"https://www.linkedin.com/in/tauboll/"
    // },
    {
        img:require("./speakers/Maurizio_Rossi.png"),
        name:"Maurizio Rossi",
        designation:"Founder",
        company:"H-Farm",
        linkedIn:"https://it.linkedin.com/in/rossimaurizio"
    },
    {
        img:require("./speakers/Ameena_Zia.png"),
        name:"Dr Ameena Zia",
        designation:"Professor ",
        company:"Brevard College",
        linkedIn:"https://www.linkedin.com/in/ameenazia/"
    },
    // {
    //     img:require("./speakers/Fabienne_Durand.png"),
    //     name:"Fabienne Durand",
    //     designation:"Senior Advisor",
    //     company:"SmartNations",
    //     linkedIn:"https://www.linkedin.com/in/fabienne-durand-4773a31b/"
    // }, 
    // {
    //     img:require("./speakers/Dr_Nicoletta_Iacobacci.png"),
    //     name:"Dr Nicoletta Iacobacci",
    //     designation:"Prof",
    //     company:"Webster & Jinan University",
    //     linkedIn:"https://www.linkedin.com/in/nicoiaco/"
    // },
    // {
    //     img:require("./speakers/Hassan_Abbas.png"),
    //     name:"Hassan Abbas",
    //     designation:"Chairman",
    //     company:"ZiZAK Pakistan",
    //     linkedIn:"http://linkedin.com/in/hassan-abbas-ph-d-6b932b15"
    // },
    // {
    //     img:require("./speakers/Arzish_Azam.png"),
    //     name:"Arzish Azam",  
    //     designation:"CEO",
    //     company:"Ejad Labs",
    //     linkedIn:"https://www.linkedin.com/in/arzishazam/"
    // },
    // {
    //     img:require("./speakers/Lars_Mortensen.png"),
    //     name:"Lars Mortensen", 
    //     designation:"Line Manager",
    //     company:"Noroff",
    //     linkedIn:"https://www.linkedin.com/in/lars-mortensen-l%C3%A6greid-011620/"
    // },
    // {
    //     img:require("./speakers/Werner_Olsen.png"),
    //     name:"Werner Olsen",
    //     designation:"Head of Smart City",
    //     company:"Innocode",
    //     linkedIn:"https://www.linkedin.com/in/werner-olsen-b7443628/"
    // }, 
    // {
    //     img:require("./speakers/Suraiya_Zaman.png"),
    //     name:"Suraiya Zaman",
    //     designation:"IT Minister",
    //     company:"Gilgit Baltistan",
    //     linkedIn:"https://www.facebook.com/suraiyazamanofficial?mibextid=LQQJ4d"
    // }, 
    // {
    //     img:require("./speakers/Mehnaz_Parveen.png"),
    //     name:"Mehnaz Parveen",
    //     designation:"Consultant",
    //     company:"UN Woman",
    //     linkedIn:"https://www.linkedin.com/in/mehnaz-parveen-a2094661/"
    // }, 
    // {
    //     img:require("./speakers/Niloufar_Gharavi.png"),
    //     name:"Niloufar Gharavi",
    //     designation:"Founder",
    //     company:"DDE",
    //     linkedIn:"https://www.linkedin.com/in/niloufargharavi/"
    // }, 
    // {
    //     img:require("./speakers/Hikari_Igarashi.png"),
    //     name:"Hikari Igarashi", 
    //     designation:"Exec. Director",
    //     company:"Women's Eye",
    //     linkedIn:"https://www.linkedin.com/in/hikari-igarashi-555b02b6/"
    // }, 
    // {
    //     img:require("./speakers/Nikolaos_Farmakis.png"),
    //     name:"Nikolaos Farmakis",
    //     designation:"Founder",
    //     company:"Smart Eiendom",
    //     linkedIn:"https://www.linkedin.com/in/nikolaos-farmakis/"
    // },
    {
        img:require("./speakers/Rupa_Dash.png"),
        name:"Rupa Dash",
        designation:"CEO",
        company:"World Woman Fund",
        linkedIn:"https://www.linkedin.com/in/rupadash"
    },
    // {
    //     img:require("./speakers/Siw_Andersen.png"),
    //     name:"Siw Andersen",
    //     designation:"CEO",
    //     company:"Oslo Business Region",
    //     linkedIn:"https://www.linkedin.com/in/siwander/"
    // },
    // {
    //     img:require("./speakers/Curt_Rice.png"),
    //     name:"Curt Rice",
    //     designation:"Prof",
    //     company:"NMBU",
    //     linkedIn:"https://www.linkedin.com/in/curtrice/"
    // },
    // {
    //     img:require("./speakers/Kristin_Anderssen.png"),
    //     name:"Kristin Anderssen",
    //     designation:"CEO",
    //     company:"Make-A-Wish Norge",
    //     linkedIn:"https://www.linkedin.com/in/kristin-anderssen/"
    // },
]
export const partners=[
    {img:require("./Partners/NEO.png")},
    {img:require("./Partners/NIO.png")},
    {img:require("./Partners/climateJam.png")},
    {img:require("./Partners/aws-company.png")},
    {img:require("./Partners/Techstars_white.png")},
    {img:require("./Partners/emergence.png")},
]


//////CT 2023//////
export const speakers2023=[
    {
        img:require("./speakers/Unni_Berge.png"),
        name:"Unni Berge",
        designation:"Head of public relation",
        company:"Norwegian EVA",
        linkedIn:"https://www.linkedin.com/in/unni-berge-4aa17985/"
    },
    {
        img:require("./speakers/Marianne_Molmen.png"),
        name:"Marianne Molmen",
        designation:"Project Manager",
        company:"NIO",
        linkedIn:"https://www.linkedin.com/in/marianne-solhaug-m%C3%B8lmen-40088b139/"
    },
    {
        img:require("./speakers/Ole_Andreas_Hagen.png"),
        name:"Ole Andreas Hagen",
        designation:"Business Director",
        company:"NHO",
        linkedIn:"https://www.linkedin.com/in/ole-andreas-hagen-195928a/"
    }, 
    {
        img:require("./speakers/Dr_Musstanser_Tinauli.png"),
        name:"Dr Musstanser Tinauli",
        designation:"CEO and Founder",
        company:"Fori",
        linkedIn:"https://no.linkedin.com/in/tinauli"
    },
    {
        img:require("./speakers/Emilie_Martinsen_Christensen.png"),
        name:"Emilie Martinsen Christensen",  
        designation:"Funding Manager",
        company:"Nordic Edge",
        linkedIn:"https://www.linkedin.com/in/emilie-martinsen-christensen/"
    }, 
    {
        img:require("./speakers/An_Ho.png"),
        name:"An Ho", 
        designation:"Head of Operations",
        company:"NIO",
        linkedIn:"https://www.linkedin.com/in/anvinhho/"
    }, 
    {
        img:require("./speakers/Dr_Frank_Riemenschneider.png"),
        name:"Dr Frank Riemenschneider",
        designation:"Prof",
        company:"Münster University",
        linkedIn:"https://www.linkedin.com/in/prof-dr-frank-riemenschneider-greif-6a26a019/"
    },
    {
        img:require("./speakers/Henrik_Botten_Taubøll.png"),
        name:"Henrik Botten Taubøll",
        designation:"Haavind",
        company:"Proptech Norway",
        linkedIn:"https://www.linkedin.com/in/tauboll/"
    },{
        img:require("./speakers/Maurizio_Rossi.png"),
        name:"Maurizio Rossi",
        designation:"Founder",
        company:"H-Farm",
        linkedIn:"https://it.linkedin.com/in/rossimaurizio"
    },{
        img:require("./speakers/Fabienne_Durand.png"),
        name:"Fabienne Durand",
        designation:"Senior Advisor",
        company:"SmartNations",
        linkedIn:"https://www.linkedin.com/in/fabienne-durand-4773a31b/"
    }, {
        img:require("./speakers/Dr_Nicoletta_Iacobacci.png"),
        name:"Dr Nicoletta Iacobacci",
        designation:"Prof",
        company:"Webster & Jinan University",
        linkedIn:"https://www.linkedin.com/in/nicoiaco/"
    },
    {
        img:require("./speakers/Hassan_Abbas.png"),
        name:"Hassan Abbas",
        designation:"Chairman",
        company:"ZiZAK Pakistan",
        linkedIn:"http://linkedin.com/in/hassan-abbas-ph-d-6b932b15"
    },
    {
        img:require("./speakers/Arzish_Azam.png"),
        name:"Arzish Azam",  
        designation:"CEO",
        company:"Ejad Labs",
        linkedIn:"https://www.linkedin.com/in/arzishazam/"
    },
    {
        img:require("./speakers/Lars_Mortensen.png"),
        name:"Lars Mortensen", 
        designation:"Line Manager",
        company:"Noroff",
        linkedIn:"https://www.linkedin.com/in/lars-mortensen-l%C3%A6greid-011620/"
    },
    {
        img:require("./speakers/Werner_Olsen.png"),
        name:"Werner Olsen",
        designation:"Head of Smart City",
        company:"Innocode",
        linkedIn:"https://www.linkedin.com/in/werner-olsen-b7443628/"
    }, {
        img:require("./speakers/Suraiya_Zaman.png"),
        name:"Suraiya Zaman",
        designation:"IT Minister",
        company:"Gilgit Baltistan",
        linkedIn:"https://www.facebook.com/suraiyazamanofficial?mibextid=LQQJ4d"
    }, {
        img:require("./speakers/Mehnaz_Parveen.png"),
        name:"Mehnaz Parveen",
        designation:"Consultant",
        company:"UN Woman",
        linkedIn:"https://www.linkedin.com/in/mehnaz-parveen-a2094661/"
    }, 
    {
        img:require("./speakers/Niloufar_Gharavi.png"),
        name:"Niloufar Gharavi",
        designation:"Founder",
        company:"DDE",
        linkedIn:"https://www.linkedin.com/in/niloufargharavi/"
    }, {
        img:require("./speakers/Hikari_Igarashi.png"),
        name:"Hikari Igarashi", 
        designation:"Exec. Director",
        company:"Women's Eye",
        linkedIn:"https://www.linkedin.com/in/hikari-igarashi-555b02b6/"
    }, 
    {
        img:require("./speakers/Nikolaos_Farmakis.png"),
        name:"Nikolaos Farmakis",
        designation:"Founder",
        company:"Smart Eiendom",
        linkedIn:"https://www.linkedin.com/in/nikolaos-farmakis/"
    },
    {
        img:require("./speakers/Rupa_Dash.png"),
        name:"Rupa Dash",
        designation:"CEO",
        company:"World Woman Fund",
        linkedIn:"https://www.linkedin.com/in/rupadash"
    },
    {
        img:require("./speakers/Siw_Andersen.png"),
        name:"Siw Andersen",
        designation:"CEO",
        company:"Oslo Business Region",
        linkedIn:"https://www.linkedin.com/in/siwander/"
    },
    {
        img:require("./speakers/Curt_Rice.png"),
        name:"Curt Rice",
        designation:"Prof",
        company:"NMBU",
        linkedIn:"https://www.linkedin.com/in/curtrice/"
    },
    {
        img:require("./speakers/Kristin_Anderssen.png"),
        name:"Kristin Anderssen",
        designation:"CEO",
        company:"Make-A-Wish Norge",
        linkedIn:"https://www.linkedin.com/in/kristin-anderssen/"
    },
]
export const partners2023=[
    {img:require("./Partners/NEO.png")},
    {img:require("./Partners/NIO.png")},
    {img:require("./Partners/climateJam.png")},
]

//////CT 2022/////
export const speakers2022=[
    {
        img:require("./speakers/Dr_Musstanser_Tinauli.png"),
        name:"Dr Musstanser Tinauli",
        designation:"CEO and Founder",
        company:"Fori",
        linkedIn:"https://no.linkedin.com/in/tinauli"
    },
    {
        img:require("./speakers/An_Ho.png"),
        name:"An Ho", 
        designation:"Head of Operations",
        company:"NIO",
        linkedIn:"https://www.linkedin.com/in/anvinhho/"
    }, 
    {
        img:require("./speakers/Henrik_Botten_Taubøll.png"),
        name:"Henrik Botten Taubøll",
        designation:"Haavind",
        company:"Proptech Norway",
        linkedIn:"https://www.linkedin.com/in/tauboll/"
    },{
        img:require("./speakers/Maurizio_Rossi.png"),
        name:"Maurizio Rossi",
        designation:"Founder",
        company:"H-Farm",
        linkedIn:"https://it.linkedin.com/in/rossimaurizio"
    }, 
    {
        img:require("./speakers/Rupa_Dash.png"),
        name:"Rupa Dash",
        designation:"CEO",
        company:"World Woman Fund",
        linkedIn:"https://www.linkedin.com/in/rupadash"
    },
    {
        img:require("./speakers/Siw_Andersen.png"),
        name:"Siw Andersen",
        designation:"CEO",
        company:"Oslo Business Region",
        linkedIn:"https://www.linkedin.com/in/siwander/"
    },
    {
        img:require("./speakers/Noman_Azhar.png"),
        name:"Noman Azhar",
        designation:"Chief Officer",
        company:"Zindigi",
        linkedIn:"https://pk.linkedin.com/in/noman-azhar-14141230/"
    },
    {
        img:require("./speakers/Catherine_Carlton.png"),
        name:"Catherine Carlton",
        designation:"Former Mayor",
        company:"Menlo Park",
        linkedIn:"https://www.linkedin.com/in/catcarlton/"
    },
    {
        img:require("./speakers/Jens_IngeIstedt.png"),
        name:"Jens IngeIstedt",
        designation:"Investment Analyst & Program Manager",
        company:"Techstars",
        linkedIn:"https://www.linkedin.com/in/ingelstedt/"
    },
    {
        img:require("./speakers/Gianfranco_Pizzuto.png"),
        name:"Gianfranco Pizzuto",
        designation:"CEO",
        company:"Automobili-estrema",
        linkedIn:"https://www.linkedin.com/in/gianfranco-pizzuto-b704b423/"
    },
    {
        img:require("./speakers/Giuseppe_Surace.png"),
        name:"Giuseppe Surace",
        designation:"Former CEO",
        company:"Buisness Growth Expert",
        linkedIn:"https://www.linkedin.com/in/giuseppesurace/"
    },
]
export const partners2022=[
    {img:require("./Partners/NIO.png")},
    {img:require("./Partners/aws-company.png")},
    {img:require("./Partners/Techstars_white.png")},
    {img:require("./Partners/emergence.png")},
]

/////Previous Speakers for Home Page///
export const previousSpeakers=[
    {
        img:require("./speakers/Unni_Berge.png"),
        name:"Unni Berge",
    },
    {
        img:require("./speakers/Marianne_Molmen.png"),
        name:"Marianne Molmen",
    },
    {
        img:require("./speakers/Ole_Andreas_Hagen.png"),
        name:"Ole Andreas",
    },
    {
        img:require("./speakers/Dr_Musstanser_Tinauli.png"),
        name:"Dr Musstanser",
    },
    {
        img:require("./speakers/Emilie_Martinsen_Christensen.png"),
        name:"Emilie Martinsen",
    },    
    {
        img:require("./speakers/An_Ho.png"),
        name:"An Ho",
    },    
    {
        img:require("./speakers/Dr_Frank_Riemenschneider.png"),
        name:"Dr Frank",
    },
    {
        img:require("./speakers/Henrik_Botten_Taubøll.png"),
        name:"Henrik Botten",
    },    
    {
        img:require("./speakers/Maurizio_Rossi.png"),
        name:"Maurizio Rossi",
    },
    {
        img:require("./speakers/Fabienne_Durand.png"),
        name:"Fabienne Durand",
    },
    {
        img:require("./speakers/Dr_Nicoletta_Iacobacci.png"),
        name:"Dr Nicoletta",
    },
    {
        img:require("./speakers/Hassan_Abbas.png"),
        name:"Hassan Abbas",
    },
]

export const aboutPage=[
    {    
        id: 1,
        heading:"Curate Tomorrow  2024",
        small:"We couldn’t be happier to bring back Curate Tomorrow, returning to the Oslo, Norway.",
        pTag:"We are absolutely thrilled to join hands with Oslo Innovation Week 2024 (OIW24) in hosting our third think-tank cluster meet up, Curate Tomorrow. This exciting event will bring together an incredible mix of delegates from diverse backgrounds, including C40 representatives, accelerators, startups, scale-ups, government leaders, diplomats, artists, and futurists and we can't wait to welcome everyone to the vibrant city of Oslo, where the event will be hosted in the heart of Oslo at NIO House.",
        img:require("./sectionImg/conference3.png"),
    },    {
        id: 2,
        heading:"Replay OIW 2023",
        pTag:"We are ecstatic and filled with joy as we reflect on the resounding success of our last year event! We would like to express our deepest appreciation to each and every passionate participant who wholeheartedly contributed to meaningful discussions and collaborated tirelessly. Your unwavering dedication and collective efforts are instrumental in making a tangible difference in the ongoing battle against climate change and in curating a sustainable tomorrow. The memories of this event are forever etched in our minds and hearts, captured in an array of vibrant pictures that eloquently tell stories of collaboration, inspiration, and the collective pursuit of a better, greener future.",
    },
    {
        id: 3,
        heading:"Fori X Oslo Innovation Week",
        pTag:"Oslo Innovation Week serves as a platform for knowledge exchange and collaboration, driving innovation and fostering connections within the global community. Join us for an excellent opportunity to forge strong partnerships, exchange tech solutions, and share sustainable ideas.",
        img:require("./sectionImg/conference4.png"),
    }
]

export const venuePage=[
    {    
        id: 1,
        heading:"The Venue",
        pTag:"Situated at the heart of Scotland’s beautiful capital city, the EICC is one of the world’s outstanding venues for conferences and events.",
        pTag1:"EICC, The Exchange, 150 Morrison St, Edinburgh EH3 8EE",
        img:require("./sectionImg/conference.png"),
    },    {
        id: 2,
        heading:"NIO House",
        pTag:"Located moments away from Waverley Station or the Royal Mile, the delights of Edinburgh are on our doorstep. 20 apartment style rooms promising top-quality comfort, full of shower beer fridges, draft beer taps, record players and more. The hotel is dog-friendly and provides dog-beds and treats. Their two bars are open to all and are pouring 30+ taps of epic craft beer alongside as their ever-popular food menu.",
        button:'View On Map',
        img:require("./sectionImg/conference.png"),
        pTag2:'We look forward to seeing you there…'
    }
]

export const footer=[
    {   
        id: 1,
        heading:"Code of Conduct",
        pTag:'We want Curate Tomorrow to be a safe and welcoming place. Please treat all your fellow delegates and the speakers with respect both in person and on social media. If we feel that anyone is disrupting the event or acting in a way not in keeping with the ethos of the event, we reserve the right to remove any individual without refund.'
    },
    {
        id: 2,
        heading:"FAQ’s",
        pTag:
        <ol>
        <li>How do I register for Curate Tomorrow? Visit our Registration page on the website and follow the simple steps to secure your spot.</li>
        <li>What is the agenda for Curate Tomorrow? Our agenda is packed with insightful sessions, workshops, and networking opportunities. You can find the detailed schedule on our Agenda page.</li>
        <li>Are there any discounts available for group bookings? Yes, we offer special group discounts. Please reach out to our team at info@curatetomorrow.com for more information.</li>
        <li>Can I present at Curate Tomorrow? We welcome speaker proposals! If you have expertise to share, please submit your proposal through our Speaker Submission page.</li>
        <li>How can I become a sponsor for Curate Tomorrow? Visit our Sponsor page on the website and follow the simple steps to become a sponsor or partner with us.</li>
        </ol>     
    },
    {
        id: 3,
        heading:"Accessibility Statement",
        pTag:'Curate Tomorrow welcomes individuals with disabilities to participate in its conferences. If you would like to discuss any special access requirements, please contact us at info@curatetomorrow.com in advance of booking.'
    }

]


