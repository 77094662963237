import React ,{useState}from 'react'
import './MobileNav.css' ;
import {Link} from 'react-router-dom'

const MobileNav = ({url}) => {
  const currentPage = window.location.pathname;
  const excludedPaths = ['/loginUser', '/registerUser', '/dashboard', '/dashboard/delegate', '/dashboard/sponsor'];
  const shouldShowBurger = !excludedPaths.includes(currentPage);


    const [show, setShow] = useState(false)
    const [firstShow, setFirstShow] = useState(true)
    const [showHistory, setShowHistory] = useState(false)


    const showFunction =()=>{
      setFirstShow(false)
      setShow(!show)
      setShowHistory(false)
    }


    const showHistoryFunction = () =>{
      setShowHistory(!showHistory)
    }
  

  return (
    <div className="Mob_Navbar">
        <div className={show?"Mob_Navbar_right":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
            <Link  to='/' onClick={showFunction}>HOME</Link>
            <Link to="/about" onClick={showFunction}>ABOUT</Link>
            <Link  to='/event' onClick={showFunction}>EVENT</Link>
            <Link  to="/speakers" onClick={showFunction}>SPEAKERS</Link>
            {/* <Link to="/venue" onClick={showFunction}>VENUE</Link> */}
            <Link to="/contact" onClick={showFunction}>CONTACT</Link>
            <Link to="/register-sponsor" onClick={showFunction}>SPONSOR</Link>
            <Link to="/checkin" onClick={showFunction}>CHECK- IN</Link>

            <Link to="" onClick={showHistoryFunction}>
              HISTORY 
              <span>
              <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10L12 15L17 10" stroke="#D22F7D" />
              </svg>
              </span>
            </Link>
            {showHistory && 
              <div className='flex_column historyChild'>
                <Link to="/2022" onClick={showFunction}>CURATE TOMORROW 2022</Link>
                <Link to="/2023" onClick={showFunction}>CURATE TOMORROW 2023</Link>
              </div>
            }


        </div>
        {shouldShowBurger && (
        <div className={show?'cross burger':'burger'} onClick={showFunction}>
          <div></div>
          <div></div>
        </div>
        )}

       
    </div>
  )
}

export default MobileNav

