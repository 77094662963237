import "./Temp_header.css";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';


const Header = () => {
  console.log('bbbcf');
  
  return (

<div className='Bannerheader'>
    <div className="header_left">
      <img src={require('../../Assets/logo/eventLogo.png')} alt="" className=" animate-element"/>
      <h6 className="separateLine  animate-element" style={{fontSize:"1rem",lineHeight:"1.2rem"}}>CURATE TOMORROW SUMMIT</h6>
    </div>
    <div className="header_right animate-element">
      <Link to="/">HOME</Link>
      <Link to="/about">ABOUT</Link>
      <Link to="/event">EVENT</Link>
      <Link to="/speakers">SPEAKERS</Link>
      {/* <Link to="/venue">VENUE</Link> */}
      <div className="dropdown">
        <Link className="dropbtn">HISTORY</Link>
          <div className="dropdown-content">
            <Link
              to="/2022"
            >
              CURATE TOMORROW 2022
            </Link>
            <Link
              to="/2023"
            >
              CURATE TOMORROW 2023
            </Link>
          </div>
      </div>
      <Link to="/contact">CONTACT</Link>
      <Link to="/register-sponsor">SPONSOR</Link>
      <Link to="/checkin">CHECK- IN</Link>



    </div>
    <Link className="btn animate-element smallPadding" to="/register-delegate">REGISTER</Link>


    {/* <button onClick={()=>toast.info('Tickets will be available soon')} className="btn animate-element smallPadding">{window.innerWidth>500?"BUY TICKETS":"TICKET"}</button> */}
</div>
    
  )
}

export default Header;