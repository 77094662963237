import React ,{useState}from 'react'
import './MobileNavDash.css' ;
import {Link} from 'react-router-dom'

const MobileNavDash = ({url}) => {
    const [show, setShow] = useState(false)
    const [firstShow, setFirstShow] = useState(true)


    const showFunction =()=>{
      setFirstShow(false)
      setShow(!show)
    }


  return (
    <div className="Mob_NavbarDash">
        <div className={show?"Mob_Navbar_rightDash":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
            <Link  to='/' onClick={showFunction}>HOME</Link>
            <Link to="/dashboard/delegate" onClick={showFunction}>Delegate</Link>
            <Link  to='/dashboard/sponsor' onClick={showFunction}>Sponsor</Link>
            <Link  to='/dashboard/entry' onClick={showFunction}>Entry</Link>
        </div>
        <div className={show?'crossDash burgerDash':'burgerDash'} onClick={showFunction}>
            <div></div>
            <div></div>
        </div>
       
    </div>
  )
}

export default MobileNavDash

