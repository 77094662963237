// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutDiv{
    flex-direction: column !important;
    padding: 0rem 18rem 0rem 18rem;
    gap: 1rem;
    z-index: 4;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 8rem 0rem 0rem 0rem;
}
@media only screen and (max-width: 1000px) {
    .aboutDiv{
        padding: 0rem 10rem 0rem 10rem;
        text-align: start;

    } 
}
@media only screen and (max-width: 800px) {
    .aboutDiv{
        padding: 0rem 3rem 0rem 3rem;
    }
}
@media only screen and (max-width: 550px) {
    .aboutDiv{
        padding: 0rem 2rem;
        margin: 3rem 0rem 0rem 0rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/AboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,8BAA8B;IAC9B,SAAS;IACT,UAAU;IACV,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,2BAA2B;AAC/B;AACA;IACI;QACI,8BAA8B;QAC9B,iBAAiB;;IAErB;AACJ;AACA;IACI;QACI,4BAA4B;IAChC;AACJ;AACA;IACI;QACI,kBAAkB;QAClB,2BAA2B;IAC/B;AACJ","sourcesContent":[".aboutDiv{\n    flex-direction: column !important;\n    padding: 0rem 18rem 0rem 18rem;\n    gap: 1rem;\n    z-index: 4;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    margin: 8rem 0rem 0rem 0rem;\n}\n@media only screen and (max-width: 1000px) {\n    .aboutDiv{\n        padding: 0rem 10rem 0rem 10rem;\n        text-align: start;\n\n    } \n}\n@media only screen and (max-width: 800px) {\n    .aboutDiv{\n        padding: 0rem 3rem 0rem 3rem;\n    }\n}\n@media only screen and (max-width: 550px) {\n    .aboutDiv{\n        padding: 0rem 2rem;\n        margin: 3rem 0rem 0rem 0rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
