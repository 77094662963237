import React from 'react';
import "./Sponsorship.css";
import SponsorshipCards from '../SponsorshipCards/SponsorshipCards';

const Sponsorship = () => {
  return (
    <div className='sponsorhip-section'>
        <div className='pkgHeading sponsorshipH'>
            <h4>Become a Partner with us</h4>
        </div>
        <div className='cardParentDiv'>
            <SponsorshipCards name='Primary Partner' price='$3,000' Perk1='Logo display in Partners' Perk2='One delegate Nominee'/>    
            <SponsorshipCards name='Silver Partner' price='$8,000' Perk1='Logo display in Partners' Perk2='Three delegate Nominees' Perk3='Two confirmed Delegates'/>    
            <SponsorshipCards name='Gold Partner' price='$15,000' Perk1='Logo display in Partners' Perk2='Five delegate Nominees' Perk3='Three confirmed Delegates'/>    
            <SponsorshipCards name='Platinum Partner' price='$30,000' Perk1='Logo display in Partners' Perk2='Ten delegate Nominees' Perk3='Five confirmed Delegates'/>    
            <SponsorshipCards name='Powered By' price='$60,000' Perk1='Logo display in Partners' Perk2='Twenty delegate Nominees' Perk3='Eight confirmed Delegates' Perk4='Seat in Selection Committee of Delegates'/>    
        </div>
    </div>
  )
}

export default Sponsorship