// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sponsorhip-section{
    position: relative;
    z-index: 1;
    background-color: var(--bg-black);
    padding: 3rem 2rem;
    margin-bottom: 3rem;
}
.sponsorshipH{
    color: var(--t-white);
    text-align: center;
}
.cardParentDiv{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 3rem;
    gap:3rem;
}
@media only screen and (max-width: 550px) {
    .cardParentDiv{
        gap: 2rem;
    }
}
@media only screen and (max-width: 300px) {
    .sponsorhip-section {
        padding: 3rem 1rem;
    }
}

















.spnsorship-card-y{
    background-color:#f9d464;
}
.spnsorship-card-r{
    background-color: #fc097c;
}
.spnsorship-card-b{
    background-color: #0db0ef;
}
.price-p>h2{
    color:#9c790e ;
}
.price-s>h2{
    color:#8e285a;
}
.price-g>h2{
    color:#196d8e;
}
.sponso-btn-r{
    background-color: #fc097c;
}
.sponso-btn-y{
    background-color: #f9d464;
}
@media only screen and (max-width:700px) {
    .spnsorship-card{
        width:20rem;

    }
}
@media only screen and (max-width:400px) {
    .spnsorship-card{
        width:16rem;

    }
}`, "",{"version":3,"sources":["webpack://./src/components/Sponsorship/Sponsorship.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,iCAAiC;IACjC,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,QAAQ;AACZ;AACA;IACI;QACI,SAAS;IACb;AACJ;AACA;IACI;QACI,kBAAkB;IACtB;AACJ;;;;;;;;;;;;;;;;;;AAkBA;IACI,wBAAwB;AAC5B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI;QACI,WAAW;;IAEf;AACJ;AACA;IACI;QACI,WAAW;;IAEf;AACJ","sourcesContent":[".sponsorhip-section{\n    position: relative;\n    z-index: 1;\n    background-color: var(--bg-black);\n    padding: 3rem 2rem;\n    margin-bottom: 3rem;\n}\n.sponsorshipH{\n    color: var(--t-white);\n    text-align: center;\n}\n.cardParentDiv{\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    text-align: center;\n    margin-top: 3rem;\n    gap:3rem;\n}\n@media only screen and (max-width: 550px) {\n    .cardParentDiv{\n        gap: 2rem;\n    }\n}\n@media only screen and (max-width: 300px) {\n    .sponsorhip-section {\n        padding: 3rem 1rem;\n    }\n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.spnsorship-card-y{\n    background-color:#f9d464;\n}\n.spnsorship-card-r{\n    background-color: #fc097c;\n}\n.spnsorship-card-b{\n    background-color: #0db0ef;\n}\n.price-p>h2{\n    color:#9c790e ;\n}\n.price-s>h2{\n    color:#8e285a;\n}\n.price-g>h2{\n    color:#196d8e;\n}\n.sponso-btn-r{\n    background-color: #fc097c;\n}\n.sponso-btn-y{\n    background-color: #f9d464;\n}\n@media only screen and (max-width:700px) {\n    .spnsorship-card{\n        width:20rem;\n\n    }\n}\n@media only screen and (max-width:400px) {\n    .spnsorship-card{\n        width:16rem;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
