import './Agenda.css'

const Agenda = ({headingTag, contentTag}) => {
  return (
    <div className="Agenda">
        <div className='Agenda_bg'></div>
        <div className='flex_row_c text_AgendaCT'>
          <div>
            <h4>{headingTag}</h4>
          </div>
        <p>{contentTag}</p>
        </div>      
    </div>
  )
}
export default Agenda