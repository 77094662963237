// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/bg/banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
    height: 100vh;
    width: 100%;
    /* position: absolute; */
    /* top: 0%; */
    text-align: center;
    z-index: 2;
    clip-path: polygon(0 0, 100% 0, 100% 93%, 74% 100%, 0 89%);
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
}
.pinkHero{
    position: absolute;
    z-index: 1;
    clip-path: polygon(100% 40%, 100% 95%, 71% 110%, 61% 100%);  
    bottom: 0%;
    right: 0%;
    background-color:rgba(201, 51, 116, 0.77);
    width: 1180.5px;
    height:708.5px;
}
.home_heading{
    /* width: 100%; */
    padding: 8rem 10%;
    position: absolute;
    color: var(--t-white);
    z-index: 5;
}
.ul{
    display: flex;
    list-style: none;
    justify-self: start;
    gap: 1rem;
}
.ul li::before{
    content: "\\2022";
    color: var(--bg-dpink);
    font-size: 1.3rem;

}
.ul li{
    display: inline;
    justify-self:flex-start;
}
@media only screen and (max-width: 850px) {
.pinkHero{  
    clip-path: polygon(100% 40%, 100% 93%, 91% 120%, 61% 100%); 
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Temp_hero/Temp_hero.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,wBAAwB;IACxB,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,0DAA0D;IAC1D,yDAAmD;IACnD,4BAA4B;IAC5B,sBAAsB;AAC1B;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,0DAA0D;IAC1D,UAAU;IACV,SAAS;IACT,yCAAyC;IACzC,eAAe;IACf,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,UAAU;AACd;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;;AAErB;AACA;IACI,eAAe;IACf,uBAAuB;AAC3B;AACA;AACA;IACI,0DAA0D;IAC1D;AACJ","sourcesContent":[".hero {\n    height: 100vh;\n    width: 100%;\n    /* position: absolute; */\n    /* top: 0%; */\n    text-align: center;\n    z-index: 2;\n    clip-path: polygon(0 0, 100% 0, 100% 93%, 74% 100%, 0 89%);\n    background-image: url('../../Assets/bg/banner.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n.pinkHero{\n    position: absolute;\n    z-index: 1;\n    clip-path: polygon(100% 40%, 100% 95%, 71% 110%, 61% 100%);  \n    bottom: 0%;\n    right: 0%;\n    background-color:rgba(201, 51, 116, 0.77);\n    width: 1180.5px;\n    height:708.5px;\n}\n.home_heading{\n    /* width: 100%; */\n    padding: 8rem 10%;\n    position: absolute;\n    color: var(--t-white);\n    z-index: 5;\n}\n.ul{\n    display: flex;\n    list-style: none;\n    justify-self: start;\n    gap: 1rem;\n}\n.ul li::before{\n    content: \"\\2022\";\n    color: var(--bg-dpink);\n    font-size: 1.3rem;\n\n}\n.ul li{\n    display: inline;\n    justify-self:flex-start;\n}\n@media only screen and (max-width: 850px) {\n.pinkHero{  \n    clip-path: polygon(100% 40%, 100% 93%, 91% 120%, 61% 100%); \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
