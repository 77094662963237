import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import {Subscription} from '../../features/Register/Register'
import './SubscribePopup.css'; // You'll need to create a CSS file for styling

const SubscribePopup = () => {
  const dispatch = useDispatch()
  const [email, setEmail] =useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(true);



  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  


const onChange = (e)=>{
  e.preventDefault()
  setEmail(e.target.value)
}

  const handleSubscribe = (e) => {
    e.preventDefault();
    dispatch(Subscription({email}))
    setEmail('')
  };

  return (
    isPopupOpen && (
      <div className="subscribe-popup">
        <div className="popup-content">
          <button className="btn close-button" onClick={handleClosePopup}>
            &times;
          </button>
          <div className='newsletterDiv'>
            <div style={{textAlign:'center'}}>
                <h4>Join Our Family</h4>
                <p>Want our exclusive offers ? <br/> Join our family now ! </p>
            </div>
            <form onSubmit={handleSubscribe}>
                <input style={{backgroundColor:'transparent', color: 'white'}} type="email" name="email" value={email} placeholder="Enter your email" onChange={onChange} required/>
                <button className='btn' type="submit">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default SubscribePopup;
