import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    entries:[],
    messageUS:'',
    isLoading:false,
    isErrorUS:false,
    isSuccessUs:false

}


// Get All entries
export const GetAllEntry = createAsyncThunk('entries/GetAllEntry', async(_, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/getAllEntry`;

        const response = await axios.get(API_URL)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const entrieslice = createSlice({
    name:'entrieslice',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        filterState:(state,action)=>{     
            state.entries = state.entries.filter((item) => item._id !== action.payload)
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllEntry.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(GetAllEntry.fulfilled, (state, action)=>{
            state.isLoading = false
            state.messageUS = action.payload.message
            if(action.payload && action.payload.length > 0){
            state.entries = action.payload
            }else{
                state.entries = []
            }
        })
        .addCase(GetAllEntry.rejected, (state, action)=>{
            state.isLoading = false
            state.isErrorUS=true
            state.messageUS = action.payload
            state.entries=[]
        })   
    }
})


export const {reset,filterState} = entrieslice.actions
export default entrieslice.reducer